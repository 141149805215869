<template>
  <a-modal v-model="visible" :title="`Редактирование: ${form.ru ? form.ru.seo_description : ''}`" width="100%" :afterClose="close">
    <template slot="footer">
      <a-button key="back" @click="close">
        Отмена
      </a-button>
      <a-button key="submit" type="primary" @click="updateAds" :disabled="isDisabled">
        Изменить
      </a-button>
    </template>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item class="text_area" label="Заголовок(ua)" required>
        <a-textarea allow-clear autoSize v-model="form.ua.seo_title"/>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Описание(ua)" required>
        <a-textarea  allow-clear autoSize v-model="form.ua.seo_description"/>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Заголовок(ru)">
        <a-textarea allow-clear autoSize v-model="form.ru.seo_title"/>
      </a-form-model-item>
      <a-form-model-item class="text_area" label="Описание(ru)">
        <a-textarea allow-clear autoSize v-model="form.ru.seo_description"/>
      </a-form-model-item>
      <a-form-model-item label="Номер">
        <a-input v-model="form.number_car"/>
      </a-form-model-item>
      <a-form-model-item label="Марка" required>
        <a-select v-model="form.car_mark_id">
          <a-select-option v-for="type in marks" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Модель" required>
        <a-select v-model="form.car_model_id">
          <a-select-option v-for="type in models" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Год выпуска" required>
        <a-input v-model="form.year_begin"/>
      </a-form-model-item>
      <a-form-model-item label="Проверенный пробег" required>
        <a-input v-model="form.mileage_verified"/>
      </a-form-model-item>
      <a-form-model-item label="Организация">
        <a-input v-model="form.organization"/>
      </a-form-model-item>
      <a-form-model-item label="Расход город">
        <a-input v-model="form.consumption_city"/>
      </a-form-model-item>
      <a-form-model-item label="Расход трасса">
        <a-input v-model="form.consumption_trail"/>
      </a-form-model-item>
      <a-form-model-item label="Расход смешанный">
        <a-input v-model="form.consumption_mixed"/>
      </a-form-model-item>
      <a-form-model-item label="Цвет">
        <a-select v-model="form.color_id" allowClear>
          <a-select-option v-for="type in colors" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Кол. дверей">
        <a-select v-model="form.door_id" allowClear>
          <a-select-option v-for="type in doors" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Привод">
        <a-select v-model="form.drive_unit_id" allowClear>
          <a-select-option v-for="type in driveUnits" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Объем ДВС">
        <a-select v-model="form.engine_capacity_id" allowClear>
          <a-select-option v-for="type in capacities" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Мощность ДВС">
        <a-select v-model="form.engine_power_id"  allowClear>
          <a-select-option v-for="type in power" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Топливо">
        <a-select v-model="form.fuel_id" allowClear>
          <a-select-option v-for="type in fuels" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Трансмиссия">
        <a-select v-model="form.transmission_id" :allowClear="true">
          <a-select-option v-for="type in transmissions" :key="type.id" :value="type.id">
            {{ type.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Последняя операция">
        <a-input v-model="form.last_operation"/>
      </a-form-model-item>
      <a-form-model-item class="checkbox">
        <a-checkbox v-model="form.is_blocked">
          Заблокировано
        </a-checkbox>
      </a-form-model-item>
      <a-form-model-item class="checkbox">
        <a-checkbox v-model="form.is_search">
          В розыске
        </a-checkbox>
      </a-form-model-item>
      <a-form-model-item class="checkbox">
        <a-checkbox v-model="form.vin_code_verified">
          Подтвержденный Vin-code
        </a-checkbox>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  name: 'EditModal',
  data() {
    return {
      visible: false,
      product: {},
      id: '',
      form: {
        number_car: '',
        vin_code_verified: '',
        car_mark_id: '',
        car_model_id: '',
        year_begin: '',
        transmission_id: '',
        drive_unit_id: '',
        fuel_id: '',
        consumption_city: '',
        consumption_trail: '',
        consumption_mixed: '',
        engine_capacity_id: '',
        engine_power_id: '',
        door_id: '',
        color_id: '',
        last_operation: '',
        is_search: '',
        mileage_verified: '',
        organization: '',
        is_blocked: '',
        ru: {},
        ua: {},
      },
      marks: [],
      models: [],
      doors: [],
      colors: [],
      flag: false,
      driveUnits: [],
      capacities: [],
      fuels: [],
      power: [],
      transmissions: [],
      isValid: false,
      rules: {
        active: [
          {
            required: true,
            message: 'Пожалуста введите статус',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  async created() {
    try {
      this.colors = (await this.$services.get('filters/colors')).data.data.colors
    } catch (e) {
      console.log(e)
    }
  },

  computed: {
    isDisabled() {
      const form = this.form
      return !(form.ua.seo_title && form.ua.seo_description && form.car_mark_id && form.car_model_id && form.year_begin && `${form.mileage_verified}`)
    },
  },

  methods: {
    async open(id) {
      this.visible = true
      this.id = id
      const form = (await this.$services.get(`admin/products/edit/${id}`)).data.data.product

      form.is_blocked = !!form.is_blocked
      form.is_search = !!form.is_search
      form.vin_code_verified = !!form.vin_code_verified
      form.last_operation = form.last_operation ? form.last_operation.split(' ')[0] : null
      form.consumption_city = form.consumption_city / 100
      form.consumption_mixed = form.consumption_mixed / 100
      form.consumption_trail = form.consumption_trail / 100
      this.form = form
      const params = {
        type: this.form.car_type_id,
      }
      await Promise.all([
        this.getMarks(params),
        this.getModels(),
        this.getDoors(params),
        this.getDriveUnit(params),
        this.getCapacities(params),
        this.getPower(params),
        this.getFuel(params),
        this.getTransmissions(params),
      ])
      this.$nextTick(() => {
        this.flag = true
      })
    },
    async getCapacities(params) {
      try {
        this.capacities = (await this.$services.get('filters/engineCapacities', { params })).data.data.engineCapacities
      } catch (e) {
        console.log(e)
      }
    },
    async getTransmissions(params) {
      try {
        this.transmissions = (await this.$services.get('filters/transmissions', { params })).data.data.transmissions
      } catch (e) {
        console.log(e)
      }
    },
    async getFuel(params) {
      try {
        this.fuels = (await this.$services.get('filters/fuels', { params })).data.data.fuels
      } catch (e) {
        console.log(e)
      }
    },
    async getPower(params) {
      try {
        this.power = (await this.$services.get('filters/enginePowers', { params })).data.data.enginePowers
      } catch (e) {
        console.log(e)
      }
    },
    async getMarks(params) {
      try {
        this.marks = (await this.$services.get('filters/marks', { params })).data.data.marks
      } catch (e) {
        console.log(e)
      }
    },
    async getDoors(params) {
      try {
        this.doors = (await this.$services.get('filters/doors', { params })).data.data.doors
      } catch (e) {
        console.log(e)
      }
    },
    async updateAds() {
      const payload = { ...this.form }
      payload.is_blocked = +payload.is_blocked
      payload.is_search = +payload.is_search
      payload.vin_code_verified = +payload.vin_code_verified
      payload.mileage_verified = `${payload.mileage_verified}`
      payload.consumption_city = payload.consumption_city * 100
      payload.consumption_mixed = payload.consumption_mixed * 100
      payload.consumption_trail = payload.consumption_trail * 100
      try {
        await this.$services.put(`admin/products/${this.id}`, { ...payload })
        this.visible = false
      } catch (e) {
        console.log(e)
      }
    },
    async getDriveUnit(params) {
      try {
        this.driveUnits = (await this.$services.get('filters/driveUnits', { params })).data.data.driveUnits
      } catch (e) {
        console.log(e)
      }
    },
    async getModels() {
      const params = {
        type: this.form.car_type_id,
        mark: this.form.car_mark_id,
      }
      try {
        this.models = (await this.$services.get('filters/models', { params })).data.data.models
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.visible = false
      this.form = {
        number_car: '',
        vin_code_verified: '',
        car_mark_id: '',
        car_model_id: '',
        year_begin: '',
        transmission_id: '',
        drive_unit_id: '',
        fuel_id: '',
        consumption_city: '',
        consumption_trail: '',
        consumption_mixed: '',
        engine_capacity_id: '',
        engine_power_id: '',
        door_id: '',
        color_id: '',
        last_operation: '',
        is_search: '',
        mileage_verified: '',
        organization: '',
        is_blocked: '',
        ru: {},
        ua: {},
      }
      this.flag = false
    },
  },
  watch: {
    'form.car_mark_id'() {
      if (this.flag && this.form.car_mark_id) {
        this.form.car_model_id = ''
        this.getModels()
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-modal {
  padding: 24px 16px;
  .ant-modal-title {
    font-weight: bolder;
    font-size: 24px;
  }
}
.ant-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .ant-form-item {
    width: calc(33% - 21px);
  }
  ::v-deep label {
    margin-bottom: 0;
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
  ::v-deep .checkbox {
    width: 100%;
    margin-bottom: 10px;
  }
  ::v-deep .text_area {
    width: calc(50% - 16px);
  }
}
</style>
