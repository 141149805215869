<template>
  <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
    <a
      slot="id"
      slot-scope="text"
      href="javascript: void(0);"
      class="btn btn-sm btn-light"
    >
      {{text}}
    </a>
    <span slot="name" slot-scope="record"> {{ record.name || record.value }}</span>
    <span slot="uniqueId" slot-scope="record">
      <a v-if="record.product_alias" :href="getProductLink(record.product_alias)" target="_blank">{{ record.unique_id }}</a>
      <template v-else>{{ record.unique_id }} </template>
    </span>
    <span slot="price" slot-scope="record">{{ record.currency_default }} {{ record.price }}</span>
    <span slot="user" slot-scope="record">{{ record.user }}</span>
    <span slot="date" slot-scope="record"> {{ record.updated_at | getFormattedDate }}</span>
    <div slot="action" slot-scope="record">
      <div v-if="!isArchive" class="mb-2 d-flex">
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$emit('editItem', record.id)">
          <i class="fe fe-edit mr-2" />
          Изменить
        </a>
        <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$emit('toPreview', record.id)">
          <small>
            <i class="fe fe-more-horizontal mr-2" />
          </small>
          Просмотр
        </a>
      </div>
      <div class=" d-flex">
        <a v-if="isArchive" href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$emit('restore', record.id)">
          <small>
            <i class="fe fe-repeat mr-2" />
          </small>
          Востановить
        </a>
        <a-popconfirm
          v-if="isArchive"
          title="Вы уверенны что хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="$emit('removeItem', record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light d-flex mr-2 align-items-center">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Удалить
          </a>
        </a-popconfirm>
        <a v-if="!isArchive" href="javascript: void(0);" class="btn btn-sm btn-light mr-2 d-flex align-items-center" @click="$emit('archive', record.id)">
          <small>
            <i class="fe fe-archive mr-2" />
          </small>
          Архивировать
        </a>
      </div>
    </div>
  </a-table>
</template>

<script>
import { getFrontUrl } from '@/utils'

export default {
  name: 'ProductsTable',

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
    types: {
      type: Array,
      default: () => {
        return []
      },
    },
    fromClient: {
      type: Boolean,
      default: false,
    },
    isOverview: {
      type: String,
      default: '',
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      columns: [],
    }
  },

  created() {
    this.setColumns()
  },

  watch: {
    list: {
      handler() {
        this.setColumns()
      },
      deep: true,
    },
  },

  methods: {
    getCurrentType(id) {
      const type = this.types.find(item => item.id === id)
      return type ? type.name : ''
    },

    setColumns() {
      this.columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Название',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: 'Уникальный ID',
          scopedSlots: { customRender: 'uniqueId' },
        },
        {
          title: 'Цена',
          scopedSlots: { customRender: 'price' },
        },
        {
          title: 'Продавец',
          dataIndex: 'user',
        },
        {
          title: 'Последние изменения',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ]
      if (this.fromClient) this.columns.splice(4, 1)
    },

    getProductLink(alias) {
      return `${getFrontUrl()}/auto/${alias}`
    },
  },
}
</script>
